.leftLogo path {
  animation: fillAnimationLeft 2s linear infinite;
}

.topLogo path {
  animation: fillAnimationTop 2s linear infinite;
}

.rightLogo path {
  animation: fillAnimationRight 2s linear infinite;
}

.bottomLogo path {
  animation: fillAnimationBottom 2s linear infinite;
}

.topLogo {
  margin-left: -20px;
  margin-bottom: 4px;
}

.bottomLogo {
  margin-left: 8px;
  margin-top: 4px;
}

.rightLogo {
  margin-left: -20px;
}

@keyframes fillAnimationLeft {
  0% {
    fill: #FFD10A;
  }

  50% {
    fill: #FFD10A;
  }

  51% {
    fill: none;
  }

  100% {
    fill: none;
  }
}

@keyframes fillAnimationTop {
  0% {
    fill: none;
  }

  12% {
    fill: none;
  }

  12.5% {
    fill: #FFD10A;
  }

  62.5% {
    fill: #FFD10A;
  }

  63% {
    fill: none;
  }

  100% {
    fill: none;
  }
}

@keyframes fillAnimationRight {
  0% {
    fill: none;
  }

  24% {
    fill: none;
  }

  24.5% {
    fill: #FFD10A;
  }

  75% {
    fill: #FFD10A;
  }

  75.5% {
    fill: none;
  }

  100% {
    fill: none;
  }
}

@keyframes fillAnimationBottom {
  0% {
    fill: none;
  }

  36% {
    fill: none;
  }

  36.5% {
    fill: #FFD10A;
  }

  87% {
    fill: #FFD10A;
  }

  87.5% {
    fill: none;
  }

  100% {
    fill: none;
  }
}